<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <h2 class="mb-5">Empirical Formula: Prelab Quiz</h2>

      <p class="mb-n3">
        a) Which of the items listed below is not required during this experiment?
      </p>

      <v-radio-group v-model="inputs.notNeeded" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in optionsNotNeededShuffled"
          :key="'pt-1-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-n3">
        b) If you weigh the crucible and crucible lid together, instead of separately, and the lid
        breaks apart in the middle of the experiment, do we have to start the experiment again?
      </p>

      <v-radio-group v-model="inputs.breakLid" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in optionsBreakLidShuffled"
          :key="'pt-2-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-n3">c) What is the purpose of adding water during the experimental procedure?</p>

      <v-radio-group v-model="inputs.waterAddition" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in optionsWaterAdditionShuffled"
          :key="'pt-3-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-n3">d) Why will the crucible and its contents gain mass when heated?</p>

      <v-radio-group v-model="inputs.massGain" class="ml-6 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in optionsMassGainShuffled"
          :key="'pt-4-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-3">
        e) Assume that you started with <number-value :value="massMg" unit="\text{g}" /> of Mg and
        obtained <latex-number :number="massMgO.toFixed(3)" unit="\text{g}" /> of the dry oxide at
        the end.
      </p>

      <p class="mb-2 pl-3">i) How many moles of Mg were used?</p>

      <calculation-input
        v-model="inputs.molMg"
        class="mb-4 pl-3"
        prepend-text="$\text{Mg}:$"
        append-text="$\text{mol}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2 pl-3">ii) What mass of oxygen reacted with the magnesium?</p>

      <calculation-input
        v-model="inputs.massO"
        class="mb-4 pl-3"
        prepend-text="$\text{Oxygen}:$"
        append-text="$\text{mass}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2 pl-3">iii) How many moles of oxygen reacted with the magnesium?</p>

      <calculation-input
        v-model="inputs.molO"
        class="mb-5 pl-3"
        prepend-text="$\text{Oxygen}:$"
        append-text="$\text{mol}$"
        :disabled="!allowEditing"
      />

      <p class="mb-n3 pl-3">iv) What is the molar ratio of magnesium to oxygen in your product?</p>

      <v-radio-group v-model="inputs.ratio" class="ml-6" :disabled="!allowEditing">
        <v-radio
          v-for="option in optionsRatio"
          :key="'pt-5-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import NumberValue from '@/tasks/components/NumberValue';
import LatexNumber from '@/tasks/components/displayers/LatexNumber.vue';
import {seededShuffle} from '@/courses/utils/seededShuffle';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';

export default {
  name: 'OleMissLab7PrelabV2',
  components: {CalculationInput, LatexNumber, NumberValue, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        notNeeded: null,
        breakLid: null,
        waterAddition: null,
        massGain: null,
        molMg: null,
        massO: null,
        molO: null,
        ratio: null,
      },
      optionsNotNeeded: [
        {text: 'Oxide strip', value: 'oxide'},
        {text: 'Mg strip', value: 'mg'},
        {text: 'Tongs', value: 'tongs'},
        {text: 'Burner', value: 'burner'},
      ],
      optionsBreakLid: [
        {text: 'No, continue the experiment without a lid', value: 'noLid'},
        {text: 'Yes, we have to start again', value: 'startAgain'},
        {
          text: 'No, continue the experiment with a new lid and use the same mass as the original',
          value: 'newLid',
        },
      ],
      optionsWaterAddition: [
        {text: 'To convert magnesium nitride to magnesium oxide', value: 'convertMg3N2toMgO'},
        {text: 'To dilute the magnesium oxide', value: 'diluteMgO'},
        {text: 'To extinguish the flame', value: 'extinguishFlame'},
        {text: 'To wash the crucible', value: 'washCrucible'},
      ],
      optionsMassGain: [
        {
          text: 'Contents of heavy porcelain containers gain weight when we heat them',
          value: 'porcelain',
        },
        {
          text: 'Formation of magnesium oxide and magnesium nitride',
          value: 'formationOfNitrideOxide',
        },
        {text: 'Because of impurities and pollution in the air', value: 'impuritiesInAir'},
        {text: 'All other answers are correct', value: 'allOthers'},
      ],
      optionsRatio: [
        {text: '$\\text{2:1}$', value: '2to1'},
        {text: '$\\text{1:11.01}$', value: '1to11'},
        {text: '$\\text{1:2}$', value: '1to2'},
        {text: '$\\text{1:1}$', value: '1to1'},
        {text: '$\\text{11.01:1}$', value: '11to1'},
      ],
    };
  },
  computed: {
    massMg() {
      return this.taskState.getValueBySymbol('massMg').content;
    },
    massMgO() {
      return this.massMg.toFloat() * 1.65826;
    },
    seed() {
      return this.$inertia.getSeed();
    },
    optionsNotNeededShuffled() {
      return seededShuffle(this.optionsNotNeeded, this.seed);
    },
    optionsBreakLidShuffled() {
      return seededShuffle(this.optionsBreakLid, this.seed);
    },
    optionsWaterAdditionShuffled() {
      return seededShuffle(this.optionsWaterAddition, this.seed);
    },
    optionsMassGainShuffled() {
      return seededShuffle(this.optionsMassGain, this.seed);
    },
  },
};
</script>
<style scoped></style>
